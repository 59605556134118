@import url(https://fast.fonts.net/lt/1.css?apiType=css&c=52198efd-826d-4c1c-8d07-30232c837b3a&fontids=1366478);
@font-face {
  font-family: 'Avenir Next W06 Regular';
  src: url(/static/media/8b1d5802-f86f-4cc1-95a8-261eabda1d22.c87bf145.woff2)
      format('woff2'),
    url(/static/media/30306912-4758-4c5f-8cd7-76a816c2e7aa.42726636.woff)
      format('woff');
}

@font-face {
  font-family: 'ostrich_sans_inlineregular';
  src: url(/static/media/ostrichsansinline-regular-webfont.d21131a6.woff2)
      format('woff2'),
    url(/static/media/ostrichsansinline-regular-webfont.887ebe9b.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ostrich_sans_inlineitalic';
  src: url(/static/media/ostrichsansinline-italic-webfont.0906ea04.woff2)
      format('woff2'),
    url(/static/media/ostrichsansinline-italic-webfont.e7dd5ce6.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Avenir Next W06 Regular', 'Helvetica Neue', sans-serif;
  font-size: 1.5rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content a,
.inline a {
  color: #666666;
  text-decoration: none;
}
.content a:hover,
.inline a:hover {
  color: #00ab9e;
}

.content,
.inline {
  padding: 0 2rem 2rem 2rem;
  margin: 0 auto;
  max-width: 50rem;
}

p,
li {
  color: black;
  font-size: 0.8rem;
  line-height: 1.5rem;
}
ul {
  margin: 0 0 0 1.5rem;
  padding: 0;
}

h1,
h2,
h3 {
  font-family: 'ostrich_sans_inlineregular';
  font-size: 64px;
  font-weight: 100;
  padding: 0;
  font-size: 3rem;
}
h2 {
  margin: 0;
  font-family: 'Avenir Next W06 Regular', 'Helvetica Neue', sans-serif;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 900;
}

h1,
.markdown h1 {
  font-family: 'ostrich_sans_inlineregular';
  font-size: 3rem;
}

h2 {
  font-size: 1.4rem;
  font-weight: 200;
  font-family: 'Avenir Next W06 Regular', 'Helvetica Neue', sans-serif;
}
h3 {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 200;
  font-family: 'Avenir Next W06 Regular', 'Helvetica Neue', sans-serif;
}
h4 {
  font-size: 3rem;
  font-weight: 200;
  margin-bottom: 0;
}
.markdown p {
  color: black;
  font-size: 1rem;
  line-height: 2rem;
}

.player-img {
  height: 5rem !important;
  width: 5rem !important;
}

input[type='range'] {
  overflow: hidden;
  -webkit-appearance: none;
  background-color: #eeeeee;
}

input[type='range']::-webkit-slider-runnable-track {
  height: 10px;
  -webkit-appearance: none;
  color: #13bba4;
  margin-top: -1px;
}

input[type='range']::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 10px;
  cursor: ew-resize;
  background: #434343;
  box-shadow: -80px 0 0 80px #43e5f7;
}

a {
  text-decoration: none;
}

.inline img {
  max-width: 100%;
}

